<template>
  <div class="hour" v-loading="isLoading" style="padding-top: 18px">
    <div class="hour-head">
      <div class="hour-head-item">
        <div class="hour-head-refresh" @click="getList">
          <img
            src="@/assets/images/realTimePositioning/map-sx.png"
            alt=""
            srcset=""
          />
          <span>刷新</span>
        </div>
      </div>
      <div class="hour-head-item">
        <div class="hour-head-twins" style="margin-left: 8px">
          <div
            class="hour-head-twins-identical hour-head-twins-right"
            style="margin-right: 18px"
          >
            报警时间
            <div class="el-date-b">
              <div class="hour-head-twins-botton" @click="handleNextDate(-1)">
                <i class="el-icon-arrow-left"></i>
              </div>
              <el-date-picker
                v-model="bodyData.dateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <!--  -->
              <div
                class="hour-head-twins-botton"
                :class="{
                  'hour-head-twins-botton-d': bodyData.dateTime == endDateTime,
                }"
                @click="handleNextDate(1)"
              >
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <div
            class="hour-head-twins-identical hour-head-twins-left"
            style="margin-right: 18px"
          >
            告警级别
            <el-select v-model="bodyData.level" placeholder="请选择">
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="hour-head-twins-identical hour-head-twins-right">
            报警类型
            <el-select v-model="bodyData.alarmTypeIdList" placeholder="请选择">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="hour-head-twins-identical hour-head-twins-left"
            style="margin-right: 18px"
          >
            处理状态
            <el-select v-model="bodyData.handleState" placeholder="请选择">
              <el-option
                v-for="item in alarmTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="hour-head-refresh"
          style="margin-left: 8px"
          @click="hadnleExport"
        >
          <img
            src="@/assets/images/realTimePositioning/hour-dc.png"
            alt=""
            srcset=""
          />
          <span>导出</span>
        </div>
      </div>
    </div>
    <div class="hour-content">
      <SCTable
        ref="SCTable"
        :tableData="tableData"
        :total="tableTotal"
        @changeConditions="onChangeConditions"
      >
        <el-table-column prop="index" label="序号" width="70">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="alarmTypeName" label="告警名称" width="180">
          <template slot-scope="scope">
            {{ scope.row.alarmTypeName }}
          </template>
        </el-table-column>
        <el-table-column prop="level" label="告警等级" width="180">
          <template slot-scope="scope">
            <div :class="['level-status' + scope.row.level]">
              {{
                scope.row.level == 1 ? "低" : scope.row.level == 2 ? "中" : "高"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="timeBegin" label="告警时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.timeBegin || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="alarmAddress" label="告警位置">
          <template slot-scope="scope">
            {{ scope.row.alarmAddress }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="车身速度" width="140">
          <template slot-scope="scope"> {{ scope.row.speed }}km/h </template>
        </el-table-column>
        <el-table-column prop="handleState" width="140" label="处理状态">
          <template slot-scope="scope">
            {{ scope.row.handleState == 0 ? "未处理" : "已处理" }}
          </template>
        </el-table-column>

        <el-table-column prop="operate" width="180px" label="操作">
          <template slot-scope="{ row }">
            <el-button
              class="polecy-button"
              @click.native.prevent="handleDetail(row)"
              type="text"
              size="small"
              >告警详情</el-button
            >
            <el-dropdown @command="handleCommand($event, row)">
              <el-button
                size="mini"
                class="polecy-button"
                style="margin-left: 10px"
                type="text"
                >处理</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1" @click.stop=""
                  >确认无风险</el-dropdown-item
                >
                <el-dropdown-item command="2" @click.stop=""
                  >误报</el-dropdown-item
                >
                <el-dropdown-item command="3" @click.stop=""
                  >风险点</el-dropdown-item
                >
                <!-- <el-dropdown-item command="4">电话联系</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </SCTable>
    </div>
    <disposeDialog
      v-model="isDispose"
      :equipmentData="currentVehicle"
      :alarmData="alarmData"
      :conditionObj="conditionObj"
      equipmentType="9"
      @changeList="getList()"
    />
    <details-dialog
      v-model="isShowDetails"
      :equipmentData="currentVehicle"
      :conditionObj="conditionObj"
      :isDownload="false"
      :details="detailsData"
      :isHandle="false"
      :isNewHandle="true"
      @changeList="getList()"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import SCTable from "@/views/RealTimePositioning/components/table";
import {
  getDeviceAlarmConf,
  historyFault,
  historyFaultExport,
  riskHandle,
} from "@/api/SystemData";
import DetailsDialog from "@/views/SecurityMonitoring/StatisticalReport/components/DetailsDialog.vue";
import {
  SCAlramList,
  getAlarmConf,
  exportSCAlramList,
  getPCZKAlarmDetail,
} from "@/api/vehicleApi";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { appConfig } from "@/config/appConfig";
import AddressText from "@/components/AddressText";
import disposeDialog from "@/views/riskControlManagement/components/disposeDialog";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
    conditionObj: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bodyData: {
        dateTime: dayjs().add(-1, "day").format("YYYY-MM-DD"),
        level: "",
        alarmTypeIdList: "",
        handleState: "",
      },
      levelOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "低",
          value: "1",
        },
        {
          label: "中",
          value: "2",
        },
        {
          label: "高",
          value: "3",
        },
      ],
      alarmTypeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "未处理",
          value: "0",
        },
        {
          label: "已处理",
          value: "1",
        },
      ], //
      typeOptions: [],
      endDateTime: dayjs().format("YYYY-MM-DD"),
      tableData: [],
      tatolTime: "0时0分",
      conditionsData: {
        pageIndex: 1,
        pageSize: 10,
      },
      tableTotal: 0,
      chartsData: {},
      editData: {},
      isVisible: false,
      isImport: false,
      visibleDialog: false,
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      isLoading: false,
      alarmData: null, //
      isDispose: false,
      isShowDetails: false,
      detailsData: {}, //
    };
  },
  components: { SCTable, AddressText, DetailsDialog, disposeDialog },
  watch: {
    bodyData: {
      handler(v) {
        this.getList("reset");
      },
      deep: true,
    },
    currentVehicle: {
      handler(v, old) {},
      immediate: true,
      deep: true,
    },
  },
  created() {
    getAlarmConf({ flag: this.currentVehicle.isCommercialVehicles ? 1 : '' })
      .then((res) => {
        if (res.obj && res.obj.length) {
          this.typeOptions = res.obj.map((val) => {
            return {
              label: val.alarmName,
              value: val.alarmId,
            };
          });
          this.typeOptions.unshift({
            label: "全部",
            value: "",
          });
        } else {
          this.typeOptions = [];
        }
      })
      .catch((err) => {
        this.typeOptions = [];
      });
  },
  methods: {
    async getList(type) {
      try {
        if (type == "reset") {
          this.$refs.SCTable.handleCurrentChange(1);
          return false;
        }
        let api = SCAlramList;
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          plate: this.currentVehicle.P,
          isCommercialVehicles: false,
          pageIndex: this.conditionsData.pageIndex,
          pageSize: this.conditionsData.pageSize,
          level: this.bodyData.level,
          alarmTypeIdList: this.bodyData.alarmTypeIdList,
          handleState: this.bodyData.handleState,
        };
        if (this.bodyData.dateTime) {
          data.alarmTimeBegin = dayjs(this.bodyData.dateTime).format(
            "YYYY-MM-DD 00:00:00"
          );
          data.alarmTimeEnd = dayjs(this.bodyData.dateTime).format(
            "YYYY-MM-DD 23:59:59"
          );
        }
        this.isLoading = true;
        let res = await api(data);
        this.isLoading = false;
        if (res.flag == 1) {
          this.tableData = res.obj.SCAlarm || [];
          this.tableTotal = res.obj.total || 0;
        }
      } catch (error) {}
    },
    onChangeConditions(e) {
      this.conditionsData = e;
      this.getList();
    },
    hadnleExport() {
      let data = {
        vehicleId: this.currentVehicle.vehicleId,
        plate: this.currentVehicle.P,
        isCommercialVehicles: false,
        pageIndex: this.conditionsData.pageIndex,
        pageSize: this.conditionsData.pageSize,
        level: this.bodyData.level,
        alarmTypeIdList: this.bodyData.alarmTypeIdList,
        handleState: this.bodyData.handleState,
      };
      if (this.bodyData.dateTime) {
        data.alarmTimeBegin = dayjs(this.bodyData.dateTime).format(
          "YYYY-MM-DD 00:00:00"
        );
        data.alarmTimeEnd = dayjs(this.bodyData.dateTime).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      exportSCAlramList(data);
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    async handleAlarmDetails(item, type) {
      if (type == "policyNo") {
        this.$router.push({
          path:
            `/routerPolicyManagement/${
              item.category == 1
                ? "ConstructionMachinery"
                : item.category == 2
                ? "CarInsurance"
                : "Insurance"
            }/policyDetails/` + item.policyId,
        });
      } else {
        this.$router.push({
          path: "/routerClaimManagement/claimDetails/" + item.id,
        });
      }
    },

    onHandleClick(row) {
      this.alarmiProp.selects = [];
      this.visibleDialog = true;
      this.alarmiProp.alarmParmas = {
        plate: row.plate, // 设备名称
        vehicleId: row.vehicleId, // 车辆Id
        groupName: row.groupName, // 车组名称
        groupId: row.groupId, // 车组Id
        alarmTime: row.timeBegin, // 报警时间
        alarmTypeEn: row.alarmTypeEn,
        alarmType: row.alarmType, // 报警类型
        alarmNameEnglish: row.alarmNameEnglish, // 报警类型
        alarmTypeCh: row.type,
        alarmAddress: row.address, // 报警地址
        alarmSourceStr: {
          1: "车载终端",
          2: "企业监控平台",
          3: "政府监管平台",
          9: "其他",
        }[row.alarmSource],
        alarmSource: row.alarmSource, // 报警来源
        alarmId: row.alarmId,
      };
    },
    handleDownloadSttachment(item) {
      // safetyManageDam({alarmId: item.alarmId})
      window.open(
        appConfig.base2GUrl2 +
          `/safetyManage/dam?alarmId=${item.alarmId}&token=${this.$store.state.appToken}&platform=PC`,
        "_blank"
      );
    },
    // 模态框关闭
    closeDialog(model) {
      this.visibleDialog = model;
    },
    // 指令下发后刷新数据
    onCmdSendChange() {
      this.isImport = false;
      this.getList();
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },

    handleCommand(e, data, type) {
      const h = this.$createElement;
      const that = this;
      switch (e) {
        case "1":
          riskHandle({
            vehicleId: data.vehicleId, //车辆id
            plate: data.plate, //车牌
            dataType: 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
            alarmId: data.id, //告警id
            alarmType: data.alarmType, //告警类型
            alarmTime: data.timeBegin, //告警时间
            remark: "", //处理内容
            createUserId: that.$store.state.userInfo.userId, //处理用户id
            createUser: that.$store.state.userInfo.name, //处理用户名
            projectName: that.conditionObj.projectName, //项目名称
            projectIds: that.conditionObj.projectId, //项目id
            customName: that.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              type ? (data.handleState = 1) : "";
              this.getList();
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: "",
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "提示 "
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "此警告确认无风险？"
          //     ),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle({
          //         vehicleId: data.vehicleId, //车辆id
          //         plate: data.plate, //车牌
          //         dataType: 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //         interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
          //         alarmId: data.id, //告警id
          //         alarmType: data.alarmType, //告警类型
          //         alarmTime: data.timeBegin, //告警时间
          //         remark: "", //处理内容
          //         createUserId: that.$store.state.userInfo.userId, //处理用户id
          //         createUser: that.$store.state.userInfo.name, //处理用户名
          //         projectName: that.conditionObj.projectName, //项目名称
          //         projectIds: that.conditionObj.projectId, //项目id
          //         customName: that.conditionObj.customName, //客户名称
          //       })
          //         .then((res) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理成功");
          //           type ? (data.handleState = 1) : "";
          //           this.getList();
          //           done();
          //         })
          //         .catch((err) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理失败");
          //           done();
          //         });
          //       // setTimeout(() => {
          //       //   done();
          //       //   setTimeout(() => {
          //       //     instance.confirmButtonLoading = false;
          //       //   }, 300);
          //       // }, 3000);
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {});
          break;
        case "2":
          riskHandle({
            vehicleId: data.vehicleId, //车辆id
            plate: data.plate, //车牌
            dataType: 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
            alarmId: data.id, //告警id
            alarmType: data.alarmType, //告警类型
            alarmTime: data.timeBegin, //告警时间
            remark: "", //处理内容
            createUserId: this.$store.state.userInfo.userId, //处理用户id
            createUser: this.$store.state.userInfo.name, //处理用户名
            projectName: this.conditionObj.projectName, //项目名称
            projectIds: this.conditionObj.projectId, //项目id
            customName: this.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              this.getList();
              type ? (data.handleState = 1) : "";
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.error("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: "",
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "提示 "
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "此告警是否误报？"
          //     ),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle({
          //         vehicleId: data.vehicleId, //车辆id
          //         plate: data.plate, //车牌
          //         dataType: 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //         interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
          //         alarmId: data.id, //告警id
          //         alarmType: data.alarmType, //告警类型
          //         alarmTime: data.timeBegin, //告警时间
          //         remark: "", //处理内容
          //         createUserId: this.$store.state.userInfo.userId, //处理用户id
          //         createUser: this.$store.state.userInfo.name, //处理用户名
          //         projectName: this.conditionObj.projectName, //项目名称
          //         projectIds: this.conditionObj.projectId, //项目id
          //         customName: this.conditionObj.customName, //客户名称
          //       })
          //         .then((res) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理成功");
          //           this.getList();
          //           type ? (data.handleState = 1) : "";
          //           done();
          //         })
          //         .catch((err) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.error("处理失败");
          //           done();
          //         });
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {});
          break;
        case "3":
          this.isDispose = true;
          this.alarmData = data;
          // this.$emit('changeDialog', 'isDispose', this.currentVehicle, this.equipmentType)
          break;
        case "4":
          // this.isDispose = true;
          this.$emit(
            "changeDialog",
            "isDispose",
            this.currentVehicle,
            this.equipmentType
          );
          break;
      }
    },
    handleDetail(e) {
      this.isShowDetails = true;
      getPCZKAlarmDetail({
        alarmId: e.id,
        day: (e.timeBegin || e.devTime || e.beginTime).split(" ")[0],
        flag: "",
      }).then((data) => {
        this.detailsData = {
          ...e,
          ...data.obj,
          // lonLat: `${e.lon},${e.lat}`,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hour {
  background: #ffffff;
  height: 100%;
  padding: 0 24px;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080ff;
      background: #e6f3ff;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }

    &-twins {
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 50px;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
      }
      &-botton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
      }
      &-botton2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0 8px;
        cursor: pointer;
        font-size: 12px;
        color: #333333;
        margin-right: 16px;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          margin-top: -2px;
        }
      }
      // &-left {
      //   border: 1px solid #DEE3E6;
      //   border-radius: 16px 0 0 16px;
      //   border-right: none;
      // }
      // &-right {
      //   border: 1px solid #DEE3E6;
      //   border-left: none;
      //   border-radius: 0 16px 16px 0;
      // }

      &-play-b {
        display: flex;
        align-items: center;
        width: 74px;
        height: 32px;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        margin-right: 16px;
        background: #0080ff;
        border-radius: 16px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          margin-left: 4px;
        }
      }
      // &-check-charts {
      //   border-color: #0080FF;
      //   border-left: 1px solid #0080FF;
      //   color: #0080FF;
      // }
    }
  }
  &-content {
    height: calc(100% - 50px);
    &-status {
      width: 66px;
      height: 30px;
      background: rgba(204, 204, 204, 0.1);
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(204, 204, 204, 0.2);
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #cccccc;
      border-radius: 2px;
    }
    &-status1 {
      font-size: 14px;
      color: #3470ff;
      background: rgba(52, 112, 255, 0.1);
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(52, 112, 255, 0.2);
    }
    &-status2 {
      font-size: 14px;
      color: #fca118;
      background: rgba(252, 161, 24, 0.1);
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(252, 161, 24, 0.2);
    }
    &-status3 {
      background: #f64a2d;
    }
  }
  .level-status1 {
    display: inline-block;
    height: 19px;
    line-height: 19px;
    font-size: 12px;
    color: #ffffff;
    padding: 0 16px;
    background: #409eff;
    border-radius: 20px 20px 20px 20px;
  }
  .level-status2 {
    display: inline-block;
    height: 19px;
    line-height: 19px;
    font-size: 12px;
    color: #ffffff;
    padding: 0 16px;
    background: #fda835;
    border-radius: 20px 20px 20px 20px;
  }
  .level-status3 {
    display: inline-block;
    height: 19px;
    line-height: 19px;
    font-size: 12px;
    color: #ffffff;
    padding: 0 16px;
    background: #f64a2d;
    border-radius: 20px 20px 20px 20px;
  }
}
</style>
